import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../layouts/de'
import SubTitle from '../components/Titles/SubTitle'

export default class PartnersPage extends React.Component {
  render() {
    const { data, location } = this.props
    const partners = data.partners.edges

    return (
      <Layout location={location}>
        <SubTitle>Partner.</SubTitle>
        <div className="flex flex-wrap justify-center w-full sm:mb-14">
          {partners.map((partner) => {
            const partnerImage = partner.node.logo &&
              partner.node.logo.localFile &&
              partner.node.logo.localFile.childImageSharp && (
                <GatsbyImage
                  className="w-full h-full"
                  image={getImage(partner.node.logo.localFile.childImageSharp)}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: '50% 50%',
                  }}
                  alt={partner.node.name}
                />
              )
            return (
              <a
                href={partner.node.link}
                className="group relative w-[250px] xl:w-[300px] h-[150px] my-7 mx-4 flex justify-center items-center flex-col"
                key={partner.node.name}
              >
                {partnerImage}
                <span className="items-center justify-center text-center text-black underline sm:hidden sm:group-hover:flex sm:group-hover:absolute sm:group-hover:h-full sm:group-hover:w-full sm:group-hover:bg-gray-200/95 sm:group-hover:p-3 font-text">
                  {partner.node.name}
                </span>
              </a>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    partners: allDirectusPartner(filter: { show: { eq: true } }) {
      edges {
        node {
          name
          link
          show
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
  }
`
